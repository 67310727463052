<template>
  <ion-page>
    <Header type="0" title="叉车列表" v-if="menuRole == 'phone'"> </Header>
    <ion-content id="taskMain" :scroll-y="true">
      <div class="dataList" id="taskPageList">
        <!-- <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher> -->
        <div class="taskCardList" v-if="taskList.length > 0">
          <task-card
            v-for="(item, index) in taskList"
            :key="index"
            :task="item"
            @nav="openTaskInfo(item)"
            style="margin-top: 10px"
          ></task-card>
        </div>
        <!-- <ion-infinite-scroll threshold="50px" @ionInfinite="nextPage($event)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
          ></ion-infinite-scroll-content>
        </ion-infinite-scroll> -->
        <!-- 无数据  -->
        <div class="no_data" v-if="taskList.length <= 0 && !islist">
          <img
            class="no_data_img no_data_top"
            src="@/assets/images/notDate/notSearch.png"
            alt
          />
          <p class="no_data_text">未查询到信息</p>
        </div>
        <CardLoading
          v-if="taskList.length <= 0 && islist"
          :isHome="false"
          lodingType="1"
        ></CardLoading>
      </div>
      <ion-fab
        horizontal="end"
        vertical="bottom"
        slot="fixed"
        @click="addTaskHandler()"
      >
        <ion-fab-button>
          <span class="iconfont icon-naotujia"></span>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  menuController,
  popoverController,
  modalController,
} from "@ionic/vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import taskCard from "./vehicleCard.vue";
import Header from "@/components/Header.vue";
import { getForklift } from "@/api/carManage/vehicleApplication";
import * as dd from "dingtalk-jsapi";
import Utils from "@/utils/Utils.ts";
import SystemUtils from "@/utils/SystemUtils";
export default {
  name: "question",
  components: {
    IonPage,
    IonContent,
    menuController,
    popoverController,
    modalController,
    Header,
    taskCard,
    CardLoading,
  },
  mounted() {
    this.getList();
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "叉车调度管理", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
  },
  data() {
    return {
      islist: false, //是否显示加载中的标识
      taskList: [],
      selSeg: 0,
      menuRole: "phone",
    };
  },
  methods: {
    getList() {
      getForklift({ userId: SystemUtils.loginUser.id }).then((res) => {
        if (!res.data.isError && res.data.data.isSuccess) {
          this.taskList = res.data.data.resultData || [];
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    openTaskInfo(event) {
      // 调度人不显示
      if(event.stateId ==1 && (event.dispatcherId!=SystemUtils.loginUser.id ||event.dispatcherId == event.creatorId )){
        this.$router.push({
          path: "/vehicleApplication",
          query: {
            id: event.id,
          },
        });
      }else{
        this.$router.push({
          path: "/vehicleInfo",
          query: {
            id: event.id,
          },
        });
      }
      
    },
    addTaskHandler() {
      this.$router.push("/vehicleApplication");
    },
  },
};
</script>
<style lang="scss" scoped>
#taskMain {
  .searchBox {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    padding: 0 px2rem(10);
    display: flex;
    justify-content: space-around;
    color: #3b3b47;
    font-size: $navigation-font-size;
    background: $default-background;
    .searchItem {
      width: 26%;
      display: flex;
      justify-content: center;
      padding: 0 px2rem(8);
      position: relative;
    }
    .searchItem1 {
      width: 22%;
    }
    .order-select {
      color: $font-segment-title-color;
    }
    p {
      font-size: $big-font-size;
    }
    .ver-line {
      width: px2rem(2);
      display: flex;
      div {
        width: px2rem(2);
        background-color: #c0c0c0;
        height: px2rem(28);
        margin: auto;
      }
    }
    .targetIndex {
      color: $font-segment-title-color;
    }
    .searchLine {
      height: px2rem(30);
      border-right: 0.01rem solid #e4ebf0;
      margin: px2rem(25) 0;
    }
    .searchSelect {
      position: absolute;
      // left: calc(50% - #{px2rem(30)});
      bottom: 0.7rem;
      width: px2rem(60);
      height: px2rem(2);
      background: $font-segment-title-color;
    }
    .iconfont {
      margin-left: px2rem(10);
      font-size: $so-very-little-font-size;
      width: px2rem(25);
    }
  }
  .allTarget {
    width: 100%;
    background: $page-background;
  }
  .segment {
    background: $page-background;
    height: 4.5rem;
    .segment_button {
      --padding-start: 1.3rem;
      --padding-end: 1.3rem;
      --padding-top: 0;
      --padding-bottom: 0;
      margin: 0 px2rem(8);
      min-height: px2rem(54);
    }

    .segment-button-checked {
      background: #ffffff;
      color: $color;
    }
  }
  .categoryBox {
    display: flex;
    justify-content: space-around;
    overflow-x: scroll;
    margin: px2rem(20) px2rem(20) px2rem(10);
    .category {
      padding: px2rem(11) px2rem(22);
      font-size: $default-font-size;
      border-radius: 2rem;
      background: #fff;
    }
    .categorySelect {
      background: #f38467;
      color: #fff;
    }
    .categoryItem {
      padding: 0 26px;
      height: 3.375rem;
      background: white;
      line-height: 3.375rem;
      text-align: center;
      color: #6d6c6c;
      font-size: $default-font-size;
    }
    .cateSelected {
      color: #0e67ed;
    }
  }
  ion-refresher {
    margin-top: px2rem(100);
  }
 
}
.title {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: bold;
  color: $color;
}
.selectOption {
  width: 100%;
  padding: 10px 12px 10px 27px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    ion-checkbox {
      margin-right: 11px;
    }
    .questionTitle {
      font-size: 13px;
      font-family: Tensentype ChaoHeiJ, Tensentype ChaoHeiJ-Bold;
      font-weight: 700;
      color: #333333;
    }
  }
  > div:nth-of-type(1) {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  > div:nth-of-type(2) {
    font-size: 11px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: bold;
    text-align: left;
    color: #333333;
    span {
      color: #ed910e;
    }
  }
  ion-button {
    --background: #ed910e;
    --padding-start: 58px;
    --padding-end: 58px;
    --background-activated: gray;
  }
}
ion-fab-button {
  --background: #0e67ed;
  // #003ca7
}
</style>
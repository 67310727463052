
  import moment from "moment";
export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data(){
    return{
      urgencyArr:['低(4小时内叉车开始作业)','中(2小时内叉车开始作业)','高(1小时内叉车开始作业)']
    }
  },
  methods: {
    formatDate(time: any) {
      return moment(time).format("YYYY-MM-DD HH:mm");
    },
    change() {
      console.log(111);
    },
  },
};

import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';
// 新建用车信息
export function newForklift(){
    return request('/forkliftManagement/newForklift.do',"POST",{userId:SystemUtils.loginUser.id,nameCN:SystemUtils.loginUser.name})
}

// 选择调度人
export function selectDispatch(data){
    return request('/forkliftManagement/selectDispatch.do',"POST",data)
}

// 发送用车信息
export function sendForklift(data){
    return request('/forkliftManagement/sendForklift.do',"POST",data)
}
// 关闭用车信息
export function closeForklift(data){
    return request('/forkliftManagement/closeForklift.do',"POST",data)
}
// 分配叉车司机
export function assignDriver(data){
    return request('/forkliftManagement/assignDriver.do',"POST",data)
}

// 查看用车信息
export function getForklift(data){
    return request('/forkliftManagement/getForklift.do',"POST",data)
}

//模具台账上传图片
export function uploadImage(data){
    return request('/forkliftManagement/uploadImage.do',"POST",data)
}
//模具台账删除图片
export function deleteImage(data){
    return request('/forkliftManagement/deleteImage.do',"POST",data)
}
// 图片上传接口

// JFVBM/forkliftManagement/uploadImage.do